import {
 ArrowPathIcon,
 CloudArrowUpIcon,
 GlobeAmericasIcon,
 LockClosedIcon,
 TrashIcon,
 UserCircleIcon,
} from '@heroicons/react/20/solid'
import React from 'react'

const features = [
 {
  name: '40%',
  description:
   'of all food produced in the U.S. is wasted, 119 billion pounds equal to $408 billion dollars worth',
  href: 'https://www.feedingamerica.org/our-work/reduce-food-waste',
  icon: TrashIcon,
 },
 {
  name: '44 million',
  description: 'people face food insecurity and hunger in the U.S.',
  href:
   'https://www.feedingamerica.org/about-us/press-room/USDA-food-security-report-2022',
  icon: UserCircleIcon,
 },
 {
  name: '170 million',
  description:
   'metric tons of CO2 equivalent per year is released due to food waste and inefficient food rescue in the U.S.',
  href:
   'https://www.usda.gov/media/blog/2022/01/24/food-waste-and-its-links-greenhouse-gases-and-climate-change',
  icon: GlobeAmericasIcon,
 },
]

export default function Stats() {
 return (
  <div className="sm:max-w-1xl mx-auto px-6 lg:max-w-6xl">
   <div className="relative isolate grid grid-cols-1 gap-x-8 gap-y-8 rounded-3xl bg-forestGreen-950 px-6 py-10 sm:gap-y-10 sm:px-10 lg:mx-0 lg:max-w-none lg:grid-cols-1 lg:items-center lg:gap-y-0">
    <div className="mx-auto max-w-4xl lg:text-center">
     <h2 className="text-center text-base font-semibold leading-7 text-softApricot-800">
      Hunger
     </h2>
     <p className="mt-2 text-center text-3xl font-bold tracking-tight text-white sm:text-4xl">
      Growing food insecurity
     </p>
     <p className="mt-6 text-lg leading-8 text-gray-300">
      The U.S. Department of Agriculture reports that in 2022, 44 million
      Americans, including 13 million children, experienced food insecurity.
      This is a 31% increase for adults and a 44% increase for children from the
      previous year, marking the highest hunger rate since 2014 and the largest
      one-year rise in food insecurity since 2008.
     </p>
    </div>
    <div className="mx-auto max-w-2xl lg:mt-10 lg:max-w-none">
     <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-3">
      {features.map((feature) => (
       <div key={feature.name} className="flex flex-col">
        <dt className="flex items-center gap-x-3 text-2xl font-semibold leading-7 text-white">
         <feature.icon
          className="h-8 w-8 flex-none text-softApricot-800"
          aria-hidden="true"
         />
         {feature.name}
        </dt>
        <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-300">
         <p className="flex-auto">{feature.description}</p>
         <p className="mt-6">
          <a
           href={feature.href}
           target="_blank"
           rel="noopener noreferrer"
           className="text-sm font-semibold leading-6 text-softApricot-800"
          >
           Learn more <span aria-hidden="true">→</span>
          </a>
         </p>
        </dd>
       </div>
      ))}
     </dl>
    </div>
   </div>
  </div>
 )
}
