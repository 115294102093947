import { CheckCircleIcon } from '@heroicons/react/20/solid'
import React from 'react'

const tiers = [
 {
  name: 'Businesses',
  id: 'businesses',
  href: '#',
  description: 'Sustainability and tax deductions',
  features: [
   'Open up new food rescue channels and partnerships to reduce waste',
   'Federal tax deductions and, complete civil and criminal liability protection for donations',
   'One platform to manage food rescue logistics across all stores',
   'Drive positive environmental and social impact, reduce your carbon footprint',
  ],
 },
 {
  name: 'Non-profits and food rescue agencies',
  id: 'nonprofit',
  href: '#',
  description: 'Partnerships and scale up',
  features: [
   'Launch and effortlessly manage an online donations marketplace for your community',
   'Discover local businesses donating food',
   'Build and manage partnerships with businesses for regular donation pickups',
   'Onboard staff and volunteers to manage food rescue and distribution efforts',
  ],
 },
 {
  name: 'Families and individuals',
  id: 'customer',
  href: '#',
  description: 'Convenient access to food',
  features: [
   'Pick up donations directly from stores and businesses, in addition to local food banks',
   'Convenient and easy app',
   'More choices and locations',
   'Food security and healthy nutrition for your household',
  ],
 },
]

export default function ValueProp() {
 return (
  <div className="bg-white py-12">
   <div className="mx-auto max-w-6xl px-10 lg:px-10">
    <div className="mx-auto max-w-4xl text-center">
     <h2 className="text-base font-semibold leading-7 text-warmTerracota-700">
      Food rescue, simplified
     </h2>
     <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900">
      Modern innovations to address food insecurity
     </p>
    </div>
    <p className="mx-auto mt-6 max-w-4xl text-lg leading-8 text-gray-600 sm:text-center">
     Our mission is to improve food security for all. We do this by making it
     easier for businesses to donate food, helping non-profit organizations grow
     and serve more of their community, and ensuring that families and
     individuals can easily rescue food from more locations.
    </p>
    <div className="mt-10 flow-root">
     <div className="isolate -mt-16 grid max-w-sm grid-cols-1 gap-y-10 divide-y divide-gray-100 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:grid-cols-3 lg:divide-x lg:divide-y-0 xl:-mx-4">
      {tiers.map((tier) => (
       <div key={tier.id} className="pt-10 lg:px-8 lg:pt-0 xl:px-14">
        <h2
         id={tier.id}
         className="text-xl font-semibold leading-7 text-gray-900"
        >
         {tier.name}
        </h2>
        <p className="text-sm font-semibold leading-6 text-gray-900">
         {tier.description}
        </p>
        <ul
         role="list"
         className="mt-6 space-y-3 text-sm leading-6 text-gray-600"
        >
         {tier.features.map((feature) => (
          <li key={feature} className="flex gap-x-3">
           <CheckCircleIcon
            className="h-6 w-5 flex-none text-warmTerracota-700"
            aria-hidden="true"
           />
           {feature}
          </li>
         ))}
        </ul>
       </div>
      ))}
     </div>
    </div>
   </div>
  </div>
 )
}
